import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Heading } from 'grommet';
import { Close } from 'grommet-icons';
import { navigate } from 'gatsby';
import { HWing } from '../linkedLogo';
import Button from '../button';
import NavAnchor from './navAnchor';
import JoinButton from '../button/joinButton';

const NAV_ITEMS = [
  {
    displayName: 'Bio',
    link: '/bio',
  },
  {
    displayName: 'Method',
    link: '/method',
  },
  {
    displayName: 'Pricing',
    link: '/pricing',
  },
  {
    displayName: 'Login',
    link: '/login',
  },
];

// opened nav styles
const OverlayBackground = styled(Box)`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
`;

const OverlayMenu = ({ setOpen, iconRowHeight, iconPad }) => (
  <OverlayBackground fill background="light-2">
    <Box
      height={iconRowHeight + 'px'}
      pad={{ horizontal: iconPad }}
      direction="row"
      justify="end"
      align="center"
    >
      <Button
        icon={<Close />}
        onClick={() => setOpen(false)}
        focusIndicator={false}
      />
    </Box>

    <Box fill="horizontal" align="center">
      <nav>
        <Box as="ul" align="center">
          {NAV_ITEMS.map(({ displayName, link }, index) => (
            <Box as="li" key={index} margin={{ top: '4vh' }}>
              <NavAnchor
                color={
                  displayName.toLowerCase() === 'login' ? 'brand' : 'dark-1'
                }
                primary={displayName.toLowerCase() === 'login' ? true : false}
                label={
                  <Heading level={2} size="medium">
                    {displayName}
                  </Heading>
                }
                //href={link}
                onClick={() => navigate(link)}
              />
            </Box>
          ))}
        </Box>
      </nav>
      <JoinButton primary margin={{ top: '5vh' }} />
    </Box>
    <Box margin={{ top: '5vh' }} fill="horizontal" align="center" justify="end">
      <HWing height="60px" width="74px" />
    </Box>
  </OverlayBackground>
);

OverlayMenu.propTypes = {
  setOpen: PropTypes.func.isRequired,
  iconRowHeight: PropTypes.number.isRequired,
  iconPad: PropTypes.string.isRequired,
};

export default OverlayMenu;
